<template>

   <nav v-if="breadcrump" class="flex" aria-label="Breadcrumb">
      <ol role="list" class="px-4 sm:px-6 lg:px-24 flex items-center space-x-4">
         <li>
            <div>
               <div @click="$router.push({name: 'Home'})" class="text-gray-400 :hover:text-gray-500 cursor-pointer">
                  <font-awesome-icon size="lg" icon="fa-solid fa-home" class="text-gray-500" />
                  <span class="sr-only">Home</span>
               </div>
            </div>
         </li>
         <li v-for="item in breadcrump" :key="item.title">
            <div class="flex items-center">
               <svg class="h-5 w-5 flex-shrink-0 text-gray-300" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                  <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
               </svg>
               <div @click="item.link ? $router.push(item.link) : null" :class="item.link ? 'cursor-pointer hover:text-gray-500' : ''" class="ml-4 text-sm font-medium text-gray-500">{{ item.title }}</div>
            </div>
         </li>
      </ol>
   </nav>
   <div class="py-10 px-4 sm:px-6 lg:px-24">
      <main class="flex flex-col gap-12">
         <div>
            <div class="flex flex-wrap items-center justify-end gap-6 sm:flex-nowrap mb-6">
               <div class="order-last flex w-full gap-x-8 text-sm font-semibold leading-6 sm:order-none sm:w-auto sm:border-r sm:border-gray-200 sm:pr-6 sm:leading-7">
                  <div v-for="item in scopes" :key="item.id" :class="item.isCurrent ? 'text-theme-active' : 'text-gray-700'" class="cursor-pointer">
                     <span v-if="!item.id.includes('custom')" @click="handleScopeUpdate(item)">{{ item.name }}</span>
                     <flat-pickr v-else v-model="dates" :config="dateRangeConfig" :class="item.isCurrent ? 'text-theme-gray-5' : 'text-theme-active'" class="border px-3 outline-none" placeholder="Custom date range" />
                  </div>
               </div>
               <h1 class="text-base font-semibold leading-7 text-gray-900">Set Time Duration</h1>
            </div>
            <h3 class="text-base font-semibold leading-6 text-gray-900">Bookings Summary</h3>
            <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
               <div class="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6">
                  <dt>
                     <div class="absolute rounded-md bg-theme-active p-3">
                        <font-awesome-icon size="1x" icon="fa-solid fa-book" class="text-white" />
                     </div>
                     <p class="ml-16 truncate text-sm font-medium text-gray-600 Booking Value uppercase">{{ bookingStats.total.name }}</p>
                  </dt>
                  <dd class="ml-16 flex items-baseline pb-6 sm:pb-7">
                     <p class="text-2xl font-semibold text-gray-900">{{ bookingStats.total.stat }}</p>
                     <div class="absolute inset-x-0 bottom-0 bg-gray-200 px-4 py-4 sm:px-6">
                        <div class="text-sm">
                           <div @click="redirectBookings()" class="cursor-pointer font-medium text-theme-active">
                              View all
                           </div>
                        </div>
                     </div>
                  </dd>
               </div>
               <div class="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6">
                  <dt>
                     <div class="absolute rounded-md bg-theme-active p-3">
                        <font-awesome-icon size="1x" icon="fa-solid fa-book" class="text-white" />
                     </div>
                     <p class="ml-16 truncate text-sm font-medium text-gray-600 Booking Value uppercase">{{ bookingStats.confirmed.name }}</p>
                  </dt>
                  <dd class="ml-16 flex items-baseline pb-6 sm:pb-7">
                     <p class="text-2xl font-semibold text-gray-900">{{ bookingStats.confirmed.stat }}</p>
                     <div class="absolute inset-x-0 bottom-0 bg-gray-200 px-4 py-4 sm:px-6">
                        <div class="text-sm">
                           <div @click="redirectBookings()" class="cursor-pointer font-medium text-theme-active">
                              View all
                           </div>
                        </div>
                     </div>
                  </dd>
               </div>
               <div class="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6">
                  <dt>
                     <div class="absolute rounded-md bg-theme-active p-3">
                        <font-awesome-icon size="1x" icon="fa-solid fa-book" class="text-white" />
                     </div>
                     <p class="ml-16 truncate text-sm font-medium text-gray-600 Booking Value uppercase">{{ bookingStats.pending.name }}</p>
                  </dt>
                  <dd class="ml-16 flex items-baseline pb-6 sm:pb-7">
                     <p class="text-2xl font-semibold text-gray-900">{{ bookingStats.pending.stat }}</p>
                     <div class="absolute inset-x-0 bottom-0 bg-gray-200 px-4 py-4 sm:px-6">
                        <div class="text-sm">
                           <div @click="redirectBookings()" class="cursor-pointer font-medium text-theme-active">
                              View all
                           </div>
                        </div>
                     </div>
                  </dd>
               </div>
               <div v-if="user.type == 'agent' && user.sub_type == null" class="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6">
                  <dt>
                     <div class="absolute rounded-md bg-theme-active p-3">
                        <font-awesome-icon size="1x" icon="fa-solid fa-book" class="text-white" />
                     </div>
                     <p class="ml-16 truncate text-sm font-medium text-gray-600 Booking Value uppercase">{{ bookingStats.subAgents.name }}</p>
                  </dt>
                  <dd class="ml-16 flex items-baseline pb-6 sm:pb-7">
                     <p class="text-2xl font-semibold text-gray-900">{{ bookingStats.subAgents.stat }}</p>
                     <div class="absolute inset-x-0 bottom-0 bg-gray-200 px-4 py-4 sm:px-6">
                        <div class="text-sm">
                           <div @click="redirectBookings()" class="cursor-pointer font-medium text-theme-active">
                              View all
                           </div>
                        </div>
                     </div>
                  </dd>
               </div>
               <div v-else-if="(user.type == 'corporate' || 'admin') || (user.type == 'agent' && user.sub_type == 'sub_agent')" class="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6">
                  <dt>
                     <div class="absolute rounded-md bg-theme-active p-3">
                        <font-awesome-icon size="1x" icon="fa-solid fa-book" class="text-white" />
                     </div>
                     <p class="ml-16 truncate text-sm font-medium text-gray-600 Booking Value uppercase">{{ bookingStats.cancelled.name }}</p>
                  </dt>
                  <dd class="ml-16 flex items-baseline pb-6 sm:pb-7">
                     <p class="text-2xl font-semibold text-gray-900">{{ bookingStats.cancelled.stat }}</p>
                     <div class="absolute inset-x-0 bottom-0 bg-gray-200 px-4 py-4 sm:px-6">
                        <div class="text-sm">
                           <div @click="redirectBookings()" class="cursor-pointer font-medium text-theme-active">
                              View all
                           </div>
                        </div>
                     </div>
                  </dd>
               </div>
            </dl>
         </div>

         <div>
            <h3 class="text-base font-semibold leading-6 text-gray-900">Sales Summary</h3>
            <dl class="mt-5 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-2 md:divide-x">
               <div class="px-4 py-5 sm:p-6">
                  <dt class="text-base font-normal text-gray-900">{{ salesStats.total.name }}</dt>
                  <dd class="mt-1 flex items-baseline justify-between md:block lg:flex">
                     <div class="flex items-baseline text-2xl font-semibold text-theme-active">
                        {{ salesStats.total.stat }}
                     </div>
                  </dd>
               </div>
               <div class="px-4 py-5 sm:p-6">
                  <dt class="text-base font-normal text-gray-900">{{ salesStats.average.name }}</dt>
                  <dd class="mt-1 flex items-baseline justify-between md:block lg:flex">
                     <div class="flex items-baseline text-2xl font-semibold text-theme-active">
                        {{ salesStats.average.stat }}
                     </div>
                  </dd>
               </div>
            </dl>
         </div>

         <div class="flex flex-row items-center justify-between">
            <div>
               <h3 class="text-base font-semibold leading-6 text-gray-900">Bookings Growth</h3>
               <h5 class="text-gray-500 mt-1 text-sm">Showing growth by booking date</h5>
            </div>
            <div>
               <ListBox name="bookings_chart" :data="dataOptions" @selected="handleSelectedOption" />
            </div>
         </div>

         <div v-if="bookingsData" class="mt-8">
            <BarChart :chartData="bookingsData" />
         </div>

         <div class="flex flex-row items-center justify-between">
            <div>
               <h3 class="text-base font-semibold leading-6 text-gray-900">Sales Growth</h3>
               <h5 class="text-gray-500 mt-1 text-sm">Showing sales growth</h5>
            </div>
            <div>
               <ListBox name="sales_chart" :data="dataOptions" @selected="handleSelectedOption" />
            </div>
         </div>
         
         <div v-if="salesData" class="mt-8">
            <BarChart :chartData="salesData" />
         </div>

      </main>
   </div>   
   
</template>

<script setup lang="ts">
import { computed, ref, watch, onMounted } from 'vue'
import { BarChart } from 'vue-chart-3'
import { Chart, registerables } from "chart.js"
import ListBox from '@/widgets/ListBox.vue'
import { logout } from '@/utils/common'
import Dashboard from '@/composables/admin/Dashboard'
import router from '@/router'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'

type ChartOption = 'daily' | 'monthly' | 'weekly'
const pages = [
  { name: 'Projects', href: '#', current: false },
  { name: 'Project Nero', href: '#', current: true },
]
const props = defineProps(['user', 'agent', 'breadcrump'])
Chart.register(...registerables)
const bookingsData = ref<any>(null)
const salesData = ref<any>(null)
const { fetchData }  = Dashboard()
const dates = ref<string | null>(null)
const subAgent = ref<any>(props.agent ? props.agent : '')

const dateRangeConfig : any = {
   mode: 'range',
   altFormat: 'd-m-Y',
   altInput: true,
   dateFormat: 'd-m-Y'
}

onMounted(async () => {

   await fetchAllData()
})

const scopes = ref<any>(
   [
      {id: 'today', name: 'Today', isCurrent: false},
      {id: 'yesterday', name: 'Yesterday', isCurrent: false},
      {id: 'last_7_days', name: 'Last 7 Days', isCurrent: false},
      {id: 'this_month', name: 'This Month', isCurrent: false},
      {id: 'last_month', name: 'Last Month', isCurrent: false},
      {id: 'all_time', name: 'All Time', isCurrent: true},
      {id: 'custom', name: 'Custom Duration', isCurrent: false}
   ]
)

const dataOptions = [
   { title: 'Daily', description: '', value: 'daily', current: true },
   { title: 'Weekly', description: '', value: 'weekly', current: false },
   { title: 'Monthly', description: '', value: 'monthly', current: false },
]

const bookingStats = ref<any>({
      total: { id: 1, name: 'Total Bookings', stat: 0 },
      confirmed: { id: 2, name: 'Confirmed', stat: 0 },
      pending: { id: 3, name: 'Pending', stat: 0 },
      subAgents: { id: 4, name: 'Agent Bookings', stat: 0 },
      cancelled: { id: 5, name: 'Cancelled Bookings', stat: 0 }
})

const salesStats = ref<any>(
   {
      total: { id: 1, name: 'Total Sales', stat: 0 },
      commission: { id: 2, name: 'Total Commission', stat: 0 },
      pending: { id: 3, name: 'Pending Payments', stat: 0 },
      average: { id: 4, name: 'Avg Booking Value', stat: 0 }
   }
)

const handleSelectedOption = async (name: string, value: ChartOption) => {

   await fetchChartData(name, value) 
}

const redirectBookings = () => {

   if (props.user.type == 'admin') {

      router.push({name: 'AdminBookings'})
   } else if (props.user.type == 'agent') {

      router.push({name: 'UserBookings', params: {userType: 'agent', subUserId: ''}})
   } else if (props.user.type == 'corporate') {

      router.push({name: 'UserBookings', params: {userType: 'corporate', subUserId: ''}})
   }
}

const fetchChartData = async (chartName: string, option: ChartOption) => {

   const response = await fetchData(chartName, option, subAgent.value)

   if (response.status == 200) {
      
      if (chartName == 'bookings_chart') {

         prepareChartData('bookings', response)
      } else if (chartName == 'sales_chart') {
         
         prepareChartData('sales', response)
      }
   }
}

const fetchAllData = async () => {

   const response = await fetchData('all', 0, subAgent.value)
   if (response.status == 200) {
      prepareChartData('bookings', response)
      prepareChartData('sales', response)
      prepareBookingStats(response)
      prepareSalesStats(response)
   } else if (response.response && response.response.status == 401) {
      logout(router)
   }
}

const fetchStats = async () => {

   const activeScope = scopes.value.find((scope: any) => scope.isCurrent)

   if (activeScope) {

      const response = await fetchData('stats', activeScope.id, subAgent.value)
      if (response.status == 200) {
         
         prepareBookingStats(response)
         prepareSalesStats(response)
      }
   }
}

const prepareChartData = (chartName: string, response: any) => {

   if (chartName == 'bookings') {

      bookingsData.value = {
         labels: response.data.bookings_chart.data.labels,
         datasets: [
            {
               label: 'Bookings',
               data: response.data.bookings_chart.data.sets,
               backgroundColor: ['#77CEFF', '#0079AF', '#123E6B', '#97B0C4', '#A5C8ED', '#77CEFF','#77CEFF','#77CEFF','#77CEFF'],
            },
         ]
      }
   } else if (chartName == 'sales') {

      salesData.value = {
         labels: response.data.sales_chart.data.labels,
         datasets: [
            {
               label: 'Sales',
               data: response.data.sales_chart.data.sets,
               backgroundColor: ['#f87979', '#f87979', '#f87979', '#f87979', '#f87979', '#f87979','#f87979','#f87979','#f87979'],
            },
         ]
      }
   }
}

const prepareBookingStats = (response: any) => {

   bookingStats.value.total.stat = response.data.bookings_summary.total_bookings
   bookingStats.value.confirmed.stat = response.data.bookings_summary.confirmed_bookings
   bookingStats.value.pending.stat = response.data.bookings_summary.pending_bookings
   bookingStats.value.subAgents.stat = response.data.bookings_summary.sub_agent_bookings
}

const prepareSalesStats = (response: any) => {

   salesStats.value.total.stat = response.data.sales_summary.total_sales
   salesStats.value.commission.stat = response.data.sales_summary.commission
   salesStats.value.pending.stat = response.data.sales_summary.pending_sales
   salesStats.value.average.stat = response.data.sales_summary.average
}

const handleScopeUpdate = async (item: any) => {

   scopes.value.forEach((scope: any) => {
      scope.isCurrent = false
   })

   scopes.value.find((scope: any) => scope.id === item.id).isCurrent = true
   dates.value = ''

   await fetchStats()
}

watch(() => props.agent, (newVallue, oldValue) => {
   
   subAgent.value = newVallue
   refetchData()
})

const refetchData = async () => {
   await fetchAllData()
}

watch(dates, (newValue, oldValue) => {

   if (newValue?.includes('to')) {
      
      scopes.value.forEach((scope: any) => {
         if (!scope.id.includes('custom')) {
            scope.isCurrent = false
         } else {
            scope.isCurrent = true
            const dateRange = `${newValue.split(' to ')[0]}_${newValue.split(' to ')[1]}`
            scope.id = `custom_${dateRange}`
         }
      })

      fetchStats().then(() => {
         
      })
   }
})

</script>