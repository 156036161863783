import { getBackendBaseUrl } from "@/utils/common"
import axios from "axios"

const baseUrl : string = getBackendBaseUrl()

const Auth = () => {

   const loginUser = async (email : string, password: string) : Promise<any> => {
      try {
         const response = await axios.post(baseUrl + 'api/login', { email: email, password: password })
         return response
      } catch (err : any) {
         return err
      }
   }

   const registerUser = async (fullname: string, companyName: string, email : string, phoneNumber: string, password: string, type: string) : Promise<any> => {
      try {
         const response = await axios.post(baseUrl + 'api/register', { name: fullname, email: email, companyName: companyName, phoneNumber: phoneNumber, password: password, type: type })
         return response
      } catch (err : any) {
         return err
      }
   }

   const forgetPassword = async (email: string) : Promise<any> => {
      try {
         const response = await axios.post(baseUrl + 'api/forget-password', { email: email, origin: window.location.origin })
         return response
      } catch (err : any) {
         return err
      }
   }

   const checkResetToken = async (token: string) : Promise<any> => {
      try {
         const response = await axios.post(baseUrl + 'api/reset-password/check-token', { token: token })
         return response
      } catch (err : any) {
         return err
      }
   }

   const checkAgentPasswordSetupToken = async (token: string) : Promise<any> => {
      try {
         const response = await axios.post(baseUrl + 'api/agent/sub-agents/check-token', { token: token })
         return response
      } catch (err : any) {
         return err
      }
   }
   
   const checkCorporatePasswordSetupToken = async (token: string) : Promise<any> => {
      try {
         const response = await axios.post(baseUrl + 'api/corporate/sub-users/check-token', { token: token })
         return response
      } catch (err : any) {
         return err
      }
   }

   const resetPassword = async (password: string, passwordConfirmation: string, token: string) : Promise<any> => {
      try {
         const response = await axios.post(baseUrl + 'api/reset-password', { password: password, passwordConfirmation: passwordConfirmation, token: token })
         return response
      } catch (err : any) {
         return err
      }
   }

   const setupAgentPassword = async (password: string, passwordConfirmation: string, token: string) : Promise<any> => {
      try {
         const response = await axios.post(baseUrl + 'api/agent/sub-agents/setup-password', { password: password, passwordConfirmation: passwordConfirmation, token: token })
         return response
      } catch (err : any) {
         return err
      }
   }
   
   const setupCorporatePassword = async (password: string, passwordConfirmation: string, token: string) : Promise<any> => {
      try {
         const response = await axios.post(baseUrl + 'api/corporate/sub-users/setup-password', { password: password, passwordConfirmation: passwordConfirmation, token: token })
         return response
      } catch (err : any) {
         return err
      }
   }

   return {
      loginUser,
      registerUser,
      forgetPassword,
      checkResetToken,
      resetPassword,
      checkAgentPasswordSetupToken,
      setupAgentPassword,
      checkCorporatePasswordSetupToken,
      setupCorporatePassword
   }
}

export default Auth