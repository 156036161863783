import { getUserToken, getBackendBaseUrl } from "@/utils/common"
import { Agent as AgentType } from "@/types/admin"
import axios from "axios"

const baseUrl : string = getBackendBaseUrl()

const Agent = () => {

   const getHeaders = () => { 
      return {
         'Authorization': `Bearer ${getUserToken()}`,
         'Content-Type': 'application/json',
      }
   }

   const subAgents = async () : Promise<any> => {
      try {
         const response = await axios.get(baseUrl + 'api/agent/sub-agents/list', {params: {}, headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }

   const createSubAgent = async (data: AgentType) : Promise<any> => {
      try {
         const response = await axios.put(baseUrl + 'api/agent/sub-agents/create', {name: data.name, email: data.email, phone: data.phone, commission: data.commission, origin: window.location.origin}, {headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }

   const updateSubAgent = async (data: AgentType) : Promise<any> => {
      try {
         const response = await axios.post(baseUrl + 'api/agent/sub-agents/update', {id: data.id, name: data.name, phone: data.phone, commission: data.commission}, {headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }

   const removeSubAgent = async (id: number) : Promise<any> => {
      try {
         const response = await axios.delete(baseUrl + 'api/agent/sub-agents/delete', { headers: getHeaders(), data: { id: id } })
         return response
      } catch (err : any) {
         return err
      }
   }

   const allAgentBookings = async () : Promise<any> => {
      try {
         const response = await axios.get(baseUrl + 'api/agent/bookings/list', {params: {}, headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }

   const subAgentBookings = async (id: string) : Promise<any> => {
      try {
         const response = await axios.get(`${baseUrl}api/agent/sub-agents/${id}/bookings/list`, {params: {}, headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }

   const agentProfile = async () : Promise<any> => {
      try {
         const response = await axios.get(`${baseUrl}api/agent/profile`, {params: {}, headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }

   const agentProfileSave = async (data: any, type: 'personal' | 'password') : Promise<any> => {
      let response
      try {
         if (type == 'personal') {
            response = await axios.post(baseUrl + 'api/agent/profile', {name: data.fullName, phone: data.phone, commission: data.commission, company: data.company, photo: data.photo}, {headers: getHeaders()})
         } else {
            response = await axios.post(baseUrl + 'api/agent/password', {currentPassword: data.current, newPassword: data.new, passwordConfirmation: data.confirmNew}, {headers: getHeaders()})
         }

         return response
      } catch (err : any) {
         return err
      }
   }

   return {
      subAgents,
      createSubAgent,
      updateSubAgent,
      removeSubAgent,
      allAgentBookings,
      subAgentBookings,
      agentProfile,
      agentProfileSave
   }
}

export default Agent