<template>

   <footer class="flex flex-col main-footer">
      <div class="bg-theme-gray-7 text-theme-gray-2">
         <div class="flex flex-col md:flex-row md:items-start mx-auto w-full lg:w-theme-dw py-12 md:space-x-12 px-12 md:px-auto gap-16 md:gap-0">
            <div class="flex-1 flex flex-row items-start space-x-8">
               <div class="relative bg-white rounded-full min-w-[50px] min-h-[50px] flex flex-row items-center justify-center">
                  <span class="font-icon theme-icon address-icon text-theme-gray-6 absolute -top-[23px] -left-[13px] text-[47px]"></span>
               </div>
               <div class="flex flex-col gap-3">
                  <a href="#" class="text-theme-active font-light text-size-6 uppercase hover:underline w-fit tracking-wider">Address</a>
                  <span class="text-theme-gray-5 font-bold md:text-size-6 text-size-4">312 Falcon Tower Diplomatic Area, Manama, Kingdom of Bahrain</span>
               </div>
            </div>
            <div class="flex-1 flex flex-row items-start justify-center space-x-8">
               <div class="relative bg-white rounded-full min-w-[50px] min-h-[50px] flex flex-row items-center justify-center">
                  <span class="font-icon theme-icon tel-icon text-theme-gray-6 absolute -top-[23px] -left-[13px] text-[47px]"></span>
               </div>
               <div class="flex flex-col gap-3">
                  <div class="text-theme-active font-light text-size-6 uppercase  tracking-wider">Phones</div>
                  <span class="text-theme-gray-5 font-bold md:text-size-6 text-size-4">
                     BOOK A RIDE: +973 3214 1440<br>OFFICE: +973 1700 1550
                  </span>
               </div>
            </div>
            <div class="flex-1 flex flex-row items-start space-x-8">
               <div class="relative bg-white rounded-full min-w-[50px] min-h-[50px] flex flex-row items-center justify-center">
                  <span class="font-icon theme-icon clock-icon text-theme-gray-6 absolute -top-[23px] -left-[13px] text-[47px]"></span>
               </div>
               <div class="flex flex-col gap-3">
                  <div class="text-theme-active font-light text-size-6 uppercase tracking-wider">Working Hours</div>
                  <span class="text-theme-gray-5 font-bold md:text-size-6 text-size-4">
                     24/7 Available
                  </span>
               </div>
            </div>
         </div>
      </div>
      <div class="flex flex-col text-center md:flex-row md:items-center mx-auto w-full lg:w-theme-dw pt-10 pb-12 md:pr-10">
         <div class="flex flex-col md:flex-row text-theme-gray-4 text-size-3 md:gap-2">
            <span>Copyright 2023</span>
            <span>Vehitra Tech W.L.L</span>
         </div>
         <div class="hidden md:flex flex-col md:items-center text-center md:flex-row items-center md:ml-auto md:mr-36 text-theme-gray-4 text-size-4 uppercase md:space-x-7">
            <div @click="$router.push({ name: 'PrivacyPolicy' })" class="text-theme-gray-4 text-size-4 uppercase group cursor-pointer">
               Privacy Policy
               <div class="h-[1px] opacity-0 w-1 bg-theme-active mb-4 group-hover:w-full group-hover:opacity-100 transition-all ease-in-out duration-500"></div>
            </div>
            <div @click="$router.push({ name: 'TermsConditions' })" class="text-theme-gray-4 text-size-4 uppercase group cursor-pointer">
               Terms & conditions
               <div class="h-[1px] opacity-0 w-1 bg-theme-active mb-4 group-hover:w-full group-hover:opacity-100 transition-all ease-in-out duration-500"></div>
            </div>
         </div>
      </div>
   </footer>

</template>

<script setup lang="ts">

</script>