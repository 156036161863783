import { getBackendBaseUrl, getUserToken } from "@/utils/common"
import { LocationSchedule } from '@/types/index'
import axios from "axios"

const baseUrl : string = getBackendBaseUrl()

const Car = () => {

   const getHeaders = () => { 
      return {
         'Content-Type': 'application/json',
         'Authorization': `Bearer ${getUserToken()}`,
      }
   }

   const searchCarsChauffer = async (pickUp: LocationSchedule) : Promise<any> => {
      try {
         
         let response

         if (getUserToken() == null) {
            response = await axios.get(baseUrl + 'api/cars/list', {params: {type: 'chauffer', id: pickUp.location.id, lat: pickUp.location.coordinates.lat, lng: pickUp.location.coordinates.lng },headers: getHeaders()})
         } else {
            response = await axios.get(baseUrl + 'api/cars/protected/list', {params: {type: 'chauffer', id: pickUp.location.id,  lat: pickUp.location.coordinates.lat, lng: pickUp.location.coordinates.lng },headers: getHeaders()})
         }

         return response
      } catch (err : any) {
         return err
      }
   }

   const searchCarsTransfer = async (pickUp: LocationSchedule, dropOff: LocationSchedule) : Promise<any> => {
      try {
         
         let response

         if (getUserToken() == null) {
            response = await axios.get(baseUrl + 'api/cars/list', {params: {type: 'transfer', pick_up: pickUp.location, drop_off: dropOff.location },headers: getHeaders()})
         } else {
            response = await axios.get(baseUrl + 'api/cars/protected/list', {params: {type: 'transfer', pick_up: pickUp.location, drop_off: dropOff.location },headers: getHeaders()})
         }

         return response
      } catch (err : any) {
         return err
      }
   }

   return {
      searchCarsChauffer,
      searchCarsTransfer
   }
}

export default Car