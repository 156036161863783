<template>

   <div v-if="layout == 'simple'" class="flex flex-col md:flex-row md:items-center md:space-x-3 divide-y divide-theme-gray-7 md:border-0 border-b border-theme-gray-7">
      <div @click="handleClick('distance')" :class="{'bg-theme-active text-white': drive === 'distance', 'bg-white hover:text-theme-active text-theme-gray-2': drive !== 'distance'}" class="text-size-1 text-center p-5 uppercase cursor-pointer">Airport Transfer</div>
      <div @click="handleClick('hourly')" :class="{'bg-theme-active text-white': drive === 'hourly', 'bg-white hover:text-theme-active text-theme-gray-2': drive !== 'hourly'}" class="text-size-1 text-center p-5 uppercase cursor-pointer">Full Day Chauffeur</div>
      <div @click="" :class="{'bg-theme-active text-white': drive === '', 'bg-white hover:text-theme-active text-theme-gray-2': drive !== ''}" class="text-size-1 text-center p-5 uppercase cursor-pointer"><a href="https://carsirent.com" target="_blank">Car Rental</a></div>
      <div @click="$router.push({name: 'MeetGreet'})" :class="{'bg-theme-active text-white': drive === '', 'bg-white hover:text-theme-active text-theme-gray-2': drive !== ''}" class="text-size-1 text-center p-5 uppercase cursor-pointer">Meet & Greet</div>
      <div @click="$router.push({name: 'BusesCoaches'})" :class="{'bg-theme-active text-white': drive === '', 'bg-white hover:text-theme-active text-theme-gray-2': drive !== ''}" class="text-size-1 text-center p-5 uppercase cursor-pointer">Buses & Coaches</div>
      <div @click="$router.push({name: 'Planmytrip'})" :class="{'bg-theme-active text-white': drive === '', 'bg-white hover:text-theme-active text-theme-gray-2': drive !== ''}" class="text-size-1 text-center p-5 uppercase cursor-pointer">Multiple Requests</div>
   </div>
   <div v-else-if="layout == 'full'" class="grid grid-cols-3 border-b-2 border-theme-active">
      <div @click="handleClick('distance')" :class="{'bg-theme-active text-white': drive === 'distance', 'bg-white hover:text-theme-active text-theme-gray-2 border border-theme-gray-7': drive !== 'distance'}" class="col-span-3 md:col-span-1 text-size-1 text-center p-5 uppercase cursor-pointer">Airport Transfer</div>
      <div @click="handleClick('hourly')" :class="{'bg-theme-active text-white': drive === 'hourly', 'bg-white hover:text-theme-active text-theme-gray-2 border border-theme-gray-7': drive !== 'hourly'}" class="col-span-3 md:col-span-1 text-size-1 text-center p-5 uppercase cursor-pointer">Full Day Chauffeur</div>
   </div>

</template>

<script setup lang="ts">
import { DriveType } from '@/types';
import { ref } from 'vue'

const props = defineProps(['layout', 'driveType'])
const emit = defineEmits(['driveType'])
const drive = ref<any>(props.driveType)

const handleClick = (type : string) => {
   drive.value = type as DriveType
   emit('driveType', type)
}

const handleRedirection = (type : string) => {
   //window.location = "booking/lead/meet_greet";
}

</script>