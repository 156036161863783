<template>
   <div class="flex flex-col h-full">
      <TopBar @openSidebar="handleSideBarOpen" />
      <main class="py-10 bg-gray-50 flex-grow">
         <div class="px-4 sm:px-6 lg:px-8">
            <slot name="content" />
         </div>
      </main>
  </div>
 </template>
 
<script setup lang="ts">
import { ref } from 'vue'
import TopBar from '@/components/account/TopBar.vue'
import SideBar from '@/components/account/SideBar.vue'

const sidebarOpen = ref(false)

const handleSideBarOpen = () => {
   sidebarOpen.value = true
}

 </script>