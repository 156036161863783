<template>

   <TopBanner layout="full-width" currentPage="Signup" heading="Signup" bannerClass="banner-auth" />
   <div class="py-24 px-11 lg:w-theme-dw md:mx-auto md:px-0">
      <div class="flex flex-col w-full lg:w-theme-auth mx-auto gap-6">
         <div class="flex flex-col gap-2">
            <label for="signup-fullname" :class="form.fullName.error ? 'text-ef-text' : 'text-theme-gray-4'" class="uppercase text-small">Full name *</label>
            <div :class="form.fullName.error ? 'border-ef-outline' : 'border-theme-gray-7'" class="flex flex-row items-center border">
               <input v-model="form.fullName.value" type="text" id="signup-fullname" class="w-full outline-none text-theme-gray-5 p-2 h-[40px] md:h-[55px] font-bold text-size-4 md:text-size-6">
            </div>
            <span v-if="form.fullName.error" class="text-ef-error">{{ form.fullName.errorMessage }}</span>
         </div>
         <div class="flex flex-col gap-2">
            <label for="signup-companyname" :class="form.companyName.error ? 'text-ef-text' : 'text-theme-gray-4'" class="uppercase text-small">Company name *</label>
            <div :class="form.companyName.error ? 'border-ef-outline' : 'border-theme-gray-7'" class="flex flex-row items-center border">
               <input v-model="form.companyName.value" type="text" id="signup-companyname" class="w-full outline-none text-theme-gray-5 p-2 h-[40px] md:h-[55px] font-bold text-size-4 md:text-size-6">
            </div>
            <span v-if="form.companyName.error" class="text-ef-error">{{ form.companyName.errorMessage }}</span>
         </div>
         <div class="flex flex-col gap-2">
            <label for="signup-email" :class="form.email.error ? 'text-ef-text' : 'text-theme-gray-4'" class="uppercase text-small">Email *</label>
            <div :class="form.email.error ? 'border-ef-outline' : 'border-theme-gray-7'" class="flex flex-row items-center border">
               <input v-model="form.email.value" type="email" id="signup-email" class="w-full outline-none text-theme-gray-5 p-2 h-[40px] md:h-[55px] font-bold text-size-4 md:text-size-6">
            </div>
            <span v-if="form.email.error" class="text-ef-error">{{ form.email.errorMessage }}</span>
         </div>
         <div class="flex flex-col gap-2">
            <label for="signup-password" :class="form.password.error ? 'text-ef-text' : 'text-theme-gray-4'" class="uppercase text-small">Password *</label>
            <div :class="form.password.error ? 'border-ef-outline' : 'border-theme-gray-7'" class="flex flex-row items-center border">
               <input v-model="form.password.value" :type="passwordFieldtype" id="signup-password" class="w-full outline-none text-theme-gray-5 p-2 h-[40px] md:h-[55px] font-bold text-size-4 md:text-size-6">
               <div @click="togglePasswordFieldType">
                  <font-awesome-icon icon="fa-solid fa-eye" class="text-theme-gray-4 cursor-pointer ml-auto mr-1" />
               </div>
            </div>
            <span v-if="form.password.error" class="text-ef-error">{{ form.password.errorMessage }}</span>
         </div>
         <div class="flex flex-col gap-2">
            <label for="signup-phonenumber" class="uppercase text-small text-theme-gray-4">Phone Number *</label>
            <div class="flex flex-row items-center border border-theme-gray-7">
               <div class="relative w-full">
                  <CountrySearchablePhoneField class="h-[40px] md:h-[55px] font-bold text-size-4 md:text-size-6 px-2" />
               </div>
            </div>
         </div>
         <div class="flex flex-col md:flex-row md:items-center gap-4 mt-2">
            <div @click="$router.push({ name: 'Login' })" class="order-2 md:order-1 gap-4 uppercase rounded-full w-full md:w-auto px-4 md:px-8 py-2 md:py-4 border-2 border-theme-gray-7 bg-theme-gray-7 hover:bg-white text-theme-gray-2 text-size-2 flex items-center justify-center cursor-pointer transition-all ease-in-out duration-500">
               I already have an account
            </div>
            <div @click="handleSignup" :class="isLoading ? 'cursor-not-allowed opacity-40' : 'cursor-pointer hover:text-theme-active hover:bg-white'" class="order-1 md:order-2 gap-4 uppercase rounded-full w-full md:w-auto px-4 md:px-8 py-2 md:py-4 border-2 border-theme-active text-white bg-theme-active text-theme-gray-2 text-size-2 flex items-center justify-center ml-auto transition-all ease-in-out duration-500">
               Sign up
            </div>
         </div>
      </div>
   </div>
   <Notification :show="notif.show" :type="notif.type" :message="notif.message" :messageDetails="notif.messageDetails" @closed="notifClosed"/>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import TopBanner from '@/components/TopBanner.vue'
import CountrySearchablePhoneField from '@/components/CountrySearchablePhoneField.vue';
import { handleApiError, isValidEmail } from '@/utils/common';
import Auth from '@/composables/Auth'
import { NotificationType } from '@/types';
import Notification from '@/widgets/Notification.vue'

const props = defineProps(['type'])
const { registerUser }  = Auth()
const isLoading = ref<boolean>(false)
const passwordFieldtype = ref<'password' | 'text'>('password')
const notif = ref<NotificationType>({ show: false, type: 'success', message: '', messageDetails: ''})
const form = ref<any>({
   fullName: { value: '', error: false, errorMessage: '' },
   companyName: { value: '', error: false, errorMessage: '' },
   email: { value: '', error: false, errorMessage: '' },
   password: { value: '', error: false, errorMessage: '' },
})

const togglePasswordFieldType = () => {
   passwordFieldtype.value = passwordFieldtype.value == 'password' ? 'text' : 'password'
}

const validateForm = () : boolean => {
   let error : boolean = false

   if (form.value.fullName.value == '') {
      form.value.fullName.error = true
      form.value.fullName.errorMessage = 'Full name is required'
      error = true
   }

   if (form.value.companyName.value == '') {
      form.value.companyName.error = true
      form.value.companyName.errorMessage = 'Company name is required'
      error = true
   }

   if (form.value.email.value == '') {
      form.value.email.error = true
      form.value.email.errorMessage = 'Email is required'
      error = true
   } else if (!isValidEmail(form.value.email.value)) {
      form.value.email.error = true
      form.value.email.errorMessage = 'Email is invalid'
      error = true
   }

   if (form.value.password.value == '') {
      form.value.password.error = true
      form.value.password.errorMessage = 'Password is required'
      error = true
   } else if (form.value.password.value.length < 6) {
      form.value.password.error = true
      form.value.password.errorMessage = 'Password must be at least 6 characters long'
      error = true
   }

   return error
}

const resetFormErrors = () :void => {
   Object.keys(form.value).forEach((key: string) => {
      form.value[key].error = false
      form.value[key].errorMessage = ''
   })
}

const resetForm = () :void => {
   Object.keys(form.value).forEach((key: string) => {
      form.value[key].value = ''
   })
}

const handleSignup = async () => {

   resetFormErrors()
   const error = validateForm()

   if (!error && !isLoading.value) {
      isLoading.value = true
      const response = await registerUser(form.value.fullName.value, form.value.companyName.value, form.value.email.value, '123', form.value.password.value, props.type)
      isLoading.value = false
      
      if (response.status == 201) {
         notif.value = { show: true, type: 'success', message: 'Success', messageDetails: 'You have created an account with us'}
         setTimeout(() => { notifClosed() }, 4000)
         resetForm()
      } else {
         const error = handleApiError(response)
         notif.value = { show: true, type: 'error', message: error, messageDetails: ''}
      }

   }
}

const notifClosed = () => {
   notif.value.show = false
}

</script>