import { getUserToken, getBackendBaseUrl } from "@/utils/common"
import { LocationSchedule, Car as CarData, UserSearch, PaymentDetails } from '@/types/index'
import axios from "axios"

const baseUrl : string = getBackendBaseUrl()

const Booking = () => {

   const getHeaders = () => { 
      return {
         'Authorization': `Bearer ${getUserToken()}`,
         'Content-Type': 'application/json',
      }
   }

   const saveBooking = async (car: CarData, search: UserSearch) : Promise<any> => {
      let response

      try {
         if (getUserToken() == null) {
            response = await axios.post(baseUrl + 'api/bookings/create', {car: car.id, search: search}, {headers: getHeaders()})
         } else {
            response = await axios.post(baseUrl + 'api/bookings/protected/create', {car: car.id, search: search}, {headers: getHeaders()})
         }

         return response
      } catch (err : any) {
         return err
      }
   }

   const updateeBooking = async (bookingNumber: string, contactDetails: any, paymentDetails: PaymentDetails, needConfirmation: boolean) : Promise<any> => {
      try {
         const response = await axios.put(baseUrl + 'api/bookings/update', {booking_number: bookingNumber, contact: contactDetails, payment: paymentDetails, need_confirmation: needConfirmation}, {headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }

   const finalizeBooking = async (bookingNumber: string, type: 'success' | 'error', responsee: any, needConfirmation: boolean) : Promise<any> => {
      try {
         const response = await axios.put(baseUrl + 'api/bookings/finalize', {booking_number: bookingNumber, response_type: type, response: responsee, need_confirmation: needConfirmation}, {headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }

   const fetchBooking = async (bookingNumber: string) : Promise<any> => {
      try {
         const response = await axios.get(`${baseUrl}api/bookings/read/${bookingNumber}`, {params: {},headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }

   return {
      saveBooking,
      fetchBooking,
      finalizeBooking,
      updateeBooking
   }
}

export default Booking