<template>
   <DataTable v-if="!isLoading"
      exportFilename="bookings"
      ref="dt"
      class="text-sm"
      v-model:filters="filters" 
      :value="bookings"
      paginator showGridlines :rows="10" 
      dataKey="id"
      filterDisplay="menu" 
      :loading="isLoading"
      :globalFilterFields="[
         'name', 
         'status', 
         'search.pickUp.date', 
         'search.pickUp.time', 
         'contact.name', 
         'contact.email', 
         'contact.phone',
         'bookedBy.name',
         'createdAt',
      ]"
   >
      <template #header>
         <div class="flex gap-2 w-full justify-end">
            <span class="flex items-center gap-2 p-input-icon-left">
               <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
            </span>
            <Button type="button" icon="pi pi-filter-slash" label="Clear" outlined @click="clearFilter()" />
            <Button icon="pi pi-external-link" label="Export" @click="exportCSV($event)" />
         </div>
      </template>
      <template #empty> No bookings found. </template>
      <template #loading> Loading bookings data. Please wait. </template>
      <Column field="number" header="Number" exportHeader="Booking Number">
         <template #body="{ data }">
            {{ data.number }}
         </template>
         <template #filter="{ filterModel }">
            <InputText v-model="filterModel.value" type="text" class="p-column-filter" placeholder="Search booking number" />
         </template>
      </Column>
      <Column field="contact.name" exportHeader="Customer Name" class="hidden">
         <template #body="{ data }">
            {{ data.contact.name }}
         </template>
      </Column>
      <Column field="contact.phone" exportHeader="Customer Phone" class="hidden">
         <template #body="{ data }">
            {{ data.contact.phone }}
         </template>
      </Column>
      <Column header="Customer Info" field="contact" :exportable="false">
         <template #body="{ data }">
            <div class="flex flex-col gap-1">
               <div>{{ data.contact.name }}</div>
               <div v-if="data.contact.name">
                  <i class="pi pi-phone" style="font-size: 0.7rem"></i> {{ data.contact.phone }}
               </div>
            </div>
         </template>
      </Column>
      <Column header="Booked Car" field="car.name"></Column>
      <Column header="Booked By" filterField="bookedBy" :showFilterMatchModes="false" :filterMenuStyle="{ width: '14rem' }" style="min-width: 14rem">
         <template #body="{ data }">
            <div class="flex items-center gap-2">
               <img v-if="data.bookedBy" class="rounded-full w-[32px] h-[32px]" :alt="data.bookedBy ? data.bookedBy.name : ''" :src="data.bookedBy.photo ? data.bookedBy.photo : '/assets/avatars/default.jpg'" />
               <span>{{ data.bookedBy ? data.bookedBy.name : 'N/A' }}</span>
            </div>
         </template>
         <template #filter="{ filterModel }">
            <MultiSelect v-model="filterModel.value" :options="mySubAgents" optionLabel="name" placeholder="Any" class="p-column-filter">
                  <template #option="slotProps">
                     <div class="flex items-center gap-2">
                        <img class="rounded-full w-[32px] h-[32px]" :alt="slotProps.option.name" :src="slotProps.option.photo" />
                        <span>{{ slotProps.option.name }}</span>
                     </div>
                  </template>
            </MultiSelect>
         </template>
      </Column>
      <Column header="Booked At" exportHeader="Booked At" style="min-width: 14rem">
         <template #body="{ data }">
            <div class="flex items-center gap-2">
               <span>{{ data.createdAt ? formattedDateTime(data.createdAt) : 'N/A' }}</span>
            </div>
         </template>
      </Column>
      <Column header="Pick up" field="search.pickUp" :exportable="false">
         <template #body="{ data }">
            {{ data.search.pickUp.date }} - {{ data.search.pickUp.time }}
         </template>
      </Column>
      <Column field="search.pickUp.location.name" exportHeader="Pick up" class="hidden">
         <template #body="{ data }">
            {{ data.search.pickUp.location.name }}
         </template>
      </Column>
      <Column field="search.pickUp.date" exportHeader="Pick up date" class="hidden">
         <template #body="{ data }">
            {{ data.search.pickUp.date }}
         </template>
      </Column>
      <Column field="search.pickUp.time" exportHeader="Pick up time" class="hidden">
         <template #body="{ data }">
            {{ data.search.pickUp.time }}
         </template>
      </Column>
      <Column header="Status" field="status">
         <template #body="{ data }">
            <div :class="getStatusClass(data.status)" class="rounded rounded-bg text-center min-w-[85px] py-2 uppercase text-xs">
               {{ data.status }}
            </div>
         </template>
         <template #filter="{ filterModel }">
            <Dropdown v-model="filterModel.value" :options="statuses" placeholder="Select One" class="p-column-filter" showClear>
                  <template #option="slotProps">
                     {{ slotProps.option }}
                  </template>
            </Dropdown>
         </template>
      </Column>
      <Column header="Actions" :exportable="false" class="w-[18rem]">
         <template #body="{ data }">
            <span @click="$router.push({name: 'UserBookingVoucher', params: {number: data.number}})" class="text-indigo-500 hover:text-indigo-600 cursor-pointer">
               Voucher
            </span> | 
            <span @click="$router.push({name: 'UserBookingInvoice', params: {number: data.number}})" class="text-indigo-500 hover:text-indigo-600 cursor-pointer">
               Invoice
            </span>
            <span v-if="data.status != 'confirmed' && isMakePaymentVisible(data.search.pickUp.date)" @click="makeBookingPayment(data)" class="text-indigo-500 hover:text-indigo-600 cursor-pointer">
               | Make Payment
            </span> 
         </template>
      </Column>
   </DataTable>
   <div v-else class="text-center font-bold h-[50px] mt-12 w-full">
      Loading data please wait...
   </div>
   <!--
      <SlideOver :show="openDialog" @closed="openDialog = false">
         <template v-slot:content>
            <BookingDetails :data="selectedBooking" />
         </template>
      </SlideOver>
   -->
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import Agent from '@/composables/admin/agent'
import Corporate from '@/composables/admin/corporate'
import { handleApiError, isLoggedIn, isAdminLoggedIn, saveBookingNumber, isMakePaymentVisible, formattedDateTime, logout } from '@/utils/common'
import SlideOver from '@/widgets/SlideOver.vue'
import BookingDetails from '@/components/admin/BookingDetails.vue'
import { STATUSES } from '@/utils/constants'
import { FilterMatchMode, FilterOperator } from 'primevue/api'
import { saveUserSearch } from '@/services/search-service'
import CarService from '@/services/car-service'
import Admin from '@/composables/admin'
import router from '@/router'

const { saveSelectedCar } = CarService()
const bookings = ref<any>(null)
const { allAgentBookings, subAgentBookings, subAgents } = Agent()
const { allCorporateBookings } = Corporate()
const { allBookings } = Admin()
const isLoading = ref<boolean>(true)
const openDialog = ref<boolean>(false)
const selectedBooking = ref<any>(null)
const props = defineProps(['subUserId']) 
let authAdminUser = ref<any>(null)

onMounted(async () => {

   const authUser = isLoggedIn()
   authAdminUser = isAdminLoggedIn()

   await fetchBookings(authUser ? authUser.user.type : '')
})

const fetchBookings = async (userType: string) => {

   isLoading.value = true
   let response : any

   if (userType == 'corporate') {

      response = await allCorporateBookings()
   } else if (userType == 'agent') {

      if (props.subUserId == '') {
         response = await allAgentBookings()
         await fetchSubAgents()
      } else {
         response = await subAgentBookings(props.subUserId)
      }
   } else if (authAdminUser) {
      response = await allBookings()
   }

   isLoading.value = false

   if (response.status == 200) {
      
      bookings.value = response.data
   } else if (response.response && response.response.status == 401) {
      logout(router)
   }
}

const fetchSubAgents = async () => {

   const response = await subAgents()

   if (response.status == 200) {
      mySubAgents.value = response.data
   } else {
      const error = handleApiError(response)
      console.log(error)
   }
}

const handleBookingDetails = (booking: any) => {

   selectedBooking.value = booking
   openDialog.value = true
}

const getStatusClass = (status: string) => {

   if (status == 'incomplete') {
      return 'bg-theme-button4-bg text-theme-button4-txt'
   } else if (status == 'confirmed') {
      return 'bg-theme-button1-bg text-theme-button1-txt'
   } else if (status == 'awaiting_payment') {
      return 'bg-theme-button2-bg text-theme-button2-txt'
   } else if (status == 'need_confirmation') {
      return 'bg-theme-button3-bg text-theme-button3-txt'
   } else if (status == 'cancelled') {
      return 'bg-theme-button5-bg text-theme-button5-txt'
   } else {
      return 'bg-theme-button-bg text-theme-button-txt'
   }
}

const filters = ref()
const statuses = STATUSES.map(status => status.title)
const mySubAgents = ref([])
const loading = ref(false)
const dt = ref()

const initFilters = () => {
   filters.value = {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      number: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
      status: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      bookedBy: { value: null, matchMode: FilterMatchMode.IN },
   }
}

initFilters()

const clearFilter = () => {
   initFilters()
}

const exportCSV = (event: any) => {
   dt.value.exportCSV()
}

const makeBookingPayment = (booking: any) => {

   saveUserSearch(booking.search)
   saveBookingNumber(booking.number)
   saveSelectedCar(booking.car)
   router.push({name: 'BookingWizard', params: {step: 'contact-details'}})
}

</script>