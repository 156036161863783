<template>
   <div class="grid grid-cols-12 md:gap-10"> 
      <div class="col-span-12 md:col-span-3 flex flex-col h-fit order-2 md:order-1 mt-12 md:mt-0">
         <BookingSummary :currentStep="currentStep" />
         <div @click="handleEditSearch" class="gap-4 uppercase mx-auto mt-12 rounded-full w-full md:px-8 py-4 border-2 border-theme-gray-7 bg-theme-gray-7 hover:bg-white text-theme-gray-2 text-size-2 flex items-center justify-center cursor-pointer ml-auto transition-all ease-in-out duration-500">
            <span class="text-theme-gray-2">
               <font-awesome-icon size="lg" icon="fa-solid fa-chevron-left" />
            </span>
            <span>Edit Search</span>
         </div>
      </div>
      <div class="col-span-12 md:col-span-9 flex flex-col order-1 md:order-2">
         <div class="flex flex-col">
            <div class="bg-theme-gray-7 px-5 py-4">
               <div class="text-theme-gray-2 uppercase text-size-2">Filter your Results</div>
            </div>
            <div class="grid grid-cols-2 border-b border-r border-l border-theme-gray-7 md:divide-x divide-y divide-theme-gray-7 shadow-custom">
               <div class="col-span-6 md:col-span-1 px-5 py-4 flex flex-col">
                  <div class="text-theme-gray-2 uppercase text-small">Passengers</div>
                  <div class="py-2">
                     <Dropdown name="passengers" :data="passengers" @item="handleDropItemSelected" />
                  </div>
               </div>
               <div class="col-span-6 md:col-span-1 px-5 py-4 flex flex-col">
                  <div class="text-theme-gray-2 uppercase text-small">Suitcases</div>
                  <div class="py-2">
                     <Dropdown name="suitcases" :data="suitcases" @item="handleDropItemSelected" />
                  </div>
               </div>
            </div>
            <div v-if="authUser" class="rounded-md bg-blue-50 p-4 mt-4" style="display:none">
               <div class="flex" >
                  <div class="ml-3 flex-1 md:flex md:justify-between" >
                     <p class="text-sm text-blue-700 font-medium">The rates below have the commission percentage you have added. In order to get net rates, please set commission as 0 in your profile.</p>
                  </div>
               </div>
            </div>
            <div v-if="isLoading"></div>
            <div v-else-if="cars && cars.length" class="flex flex-col mt-10 divide-y divide-theme-gray-7">
               <CarResult v-for="(car, index) in filteredCars" :key="index" :car="car" :canSelect="true" :selectedCar="selectedCar" @selected="handleCarSelected" />
            </div>
            <div v-else class="my-12 text-center">
               <h3 class="mt-2 text-lg font-semibold text-gray-900">No results</h3>
               <p class="mt-1 text-sm text-gray-500">Unfortunately we have found cars matching your criteria</p>
            </div>
            <div class="hidden flex flex-col">
               <div class="flex flex-row items-center gap-5">
                  <div class="rounded-full border-2 border-theme-gray-7 flex flex-row items-center justify-center w-[56px] h-[56px]">
                     <font-awesome-icon size="xl" icon="fa-solid fa-cart-shopping" class="text-theme-active" />
                  </div>
                  <div class="text-theme-gray-5 text-size-8 md:text-head-md-3">Extra Options</div>
                  <div @click="toggleExtraOptions" class="flex flex-row items-center gap-3 group cursor-pointer">
                     <div class="p-2 flex items-center justify-center rounded-full border border-theme-gray-7 bg-theme-gray-7 group-hover:bg-theme-gray-7">
                        <font-awesome-icon size="xs" icon="fa-solid fa-chevron-down" class="text-theme-gray-6" />
                     </div>
                     <div class="uppercase text-theme-gray-2 text-size-2 tracking-wide group-hover:text-theme-gray-3">Hide</div>
                  </div>
               </div>
               <div class="extra-options flex flex-col divide-y divide-theme-gray-7 border-t border-b border-theme-gray-7 mt-12">
                  <div class="grid grid-cols-6 md:divide-x divide-theme-gray-7">
                     <div class="col-span-6 md:col-span-4 md:px-5 py-4 flex flex-col">
                        <div class="flex flex-col">
                           <div class="text-theme-gray-5 font-bold text-size-6">Child Seat</div>
                           <div class="text-theme-gray-2 font-light text-size-4">Baby car seat for children aged 0-36 months</div>
                           <div class="border border-theme-gray-7 p-4 mt-2">
                              <div class="uppercase text-theme-gray-2 text-small tracking-wide group-hover:text-theme-gray-3">Notes</div>
                              <textarea class="outline-none w-full" rows="4"></textarea>
                           </div>
                        </div>
                     </div>
                     <div class="col-span-6 md:col-span-1 relative px-5 py-4 flex flex-col border border-theme-gray-7 md:border-0 md:items-center justify-center">
                        <div class="flex flex-col gap-2">
                           <div class="text-theme-gray-2 text-small uppercase">Number</div>
                           <input class="w-[20px] outline-none text-theme-gray-5 text-size-6 font-bold" value="1">
                        </div>
                        <div class="flex flex-col absolute top-1/2 right-0 transform -translate-y-1/2">
                           <div class="flex items-center justify-center w-[30px] h-[30px] text-theme-active text-size-6 bg-white border-b border-l border-t border-theme-gray-7 cursor-pointer hover:bg-theme-active hover:text-white transition-all ease-in-out duration-500">+</div>
                           <div class="flex items-center justify-center w-[30px] h-[30px] text-theme-active text-head-md bg-white border-b border-l border-t border-theme-gray-7 cursor-pointer hover:bg-theme-active hover:text-white transition-all ease-in-out duration-500">-</div>
                        </div>
                     </div>
                     <div class="col-span-6 md:col-span-1 md:px-5 py-4 flex flex-col items-center justify-center">
                        <div class="gap-2 uppercase rounded-full px-8 py-4 border-2 border-theme-gray-7 bg-theme-gray-7 hover:bg-white text-theme-gray-2 text-size-2 flex items-center justify-center cursor-pointer w-full md:w-auto md:ml-auto transition-all ease-in-out duration-500">
                           <span>Select</span>
                           <span></span>
                        </div>
                     </div>
                  </div>
                  <div class="grid grid-cols-6 md:divide-x divide-theme-gray-7">
                     <div class="col-span-6 md:col-span-4 md:px-5 py-4 flex flex-col">
                        <div class="flex flex-col">
                           <div class="text-theme-gray-5 font-bold text-size-6">Bouquet of Flowers</div>
                           <div class="text-theme-gray-2 font-light text-size-4">A bouquet of seasonal flowers prepared by a local florist</div>
                        </div>
                     </div>
                     <div class="col-span-6 md:col-span-1 relative px-5 py-4 flex flex-col border border-theme-gray-7 md:border-0 md:items-center justify-center">
                        <div class="flex flex-col gap-2">
                           <div class="text-theme-gray-2 text-small uppercase">Number</div>
                           <input class="w-[20px] outline-none text-theme-gray-5 text-size-6 font-bold" value="1">
                        </div>
                        <div class="flex flex-col absolute top-1/2 right-0 transform -translate-y-1/2">
                           <div class="flex items-center justify-center w-[30px] h-[30px] text-theme-active text-size-6 bg-white border-b border-l border-t border-theme-gray-7 cursor-pointer hover:bg-theme-active hover:text-white transition-all ease-in-out duration-500">+</div>
                           <div class="flex items-center justify-center w-[30px] h-[30px] text-theme-active text-head-md bg-white border-b border-l border-t border-theme-gray-7 cursor-pointer hover:bg-theme-active hover:text-white transition-all ease-in-out duration-500">-</div>
                        </div>
                     </div>
                     <div class="col-span-6 md:col-span-1 md:px-5 py-4 flex flex-col items-center justify-center">
                        <div class="gap-2 uppercase rounded-full px-8 py-4 border-2 border-theme-gray-7 bg-theme-active text-white text-size-2 flex items-center justify-center cursor-pointer w-full md:w-auto md:ml-auto transition-all ease-in-out duration-500">
                           <span>Select</span>
                           <span>
                              <font-awesome-icon size="lg" icon="fa-solid fa-check" class="text-white" />
                           </span>
                        </div>
                     </div>
                  </div>
                  <div class="grid grid-cols-6 md:divide-x divide-theme-gray-7">
                     <div class="col-span-6 md:col-span-5 md:px-5 py-4 flex flex-col">
                        <div class="flex flex-col">
                           <div class="text-theme-gray-5 font-bold text-size-6">Airport Assistance and Hostess Service</div>
                           <div class="text-theme-gray-2 font-light text-size-4">One of our hostesses will accompany you throughout your stay at the airport until the departure of your aircraft</div>
                        </div>
                     </div>
                     <div class="col-span-6 md:col-span-1 md:px-5 py-4 flex flex-col">
                        <div class="gap-2 uppercase rounded-full px-8 py-4 border-2 border-theme-gray-7 bg-theme-gray-7 hover:bg-white text-theme-gray-2 text-size-2 flex items-center justify-center cursor-pointer w-full md:w-auto md:ml-auto transition-all ease-in-out duration-500">
                           <span>Select</span>
                           <span></span>
                        </div>
                     </div>
                  </div>
                  <div class="grid grid-cols-6 md:divide-x divide-theme-gray-7">
                     <div class="col-span-6 md:col-span-5 md:px-5 py-4 flex flex-col">
                        <div class="flex flex-col">
                           <div class="text-theme-gray-5 font-bold text-size-6">Bodyguard Service</div>
                           <div class="text-theme-gray-2 font-light text-size-4">Skilled, professional bodyguard for private and executive VIP protection will accompany you throughout the trip and stops</div>
                        </div>
                     </div>
                     <div class="col-span-6 md:col-span-1 md:px-5 py-4 flex flex-col">
                        <div class="gap-2 uppercase rounded-full px-8 py-4 border-2 border-theme-gray-7 bg-theme-gray-7 hover:bg-white text-theme-gray-2 text-size-2 flex items-center justify-center cursor-pointer w-full md:w-auto md:ml-auto transition-all ease-in-out duration-500">
                           <span>Select</span>
                           <span></span>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div v-if="cars && cars.length" ref="bookingProceed" @click="handleBookingProceed" :class="selectedCar && !isBusy ? 'hover:bg-white hover:text-theme-active cursor-pointer' : 'cursor-not-allowed'" class="gap-4 uppercase mt-12 rounded-full px-8 py-4 border-2 border-theme-active bg-theme-active text-white text-size-2 flex items-center justify-center w-full md:w-auto md:ml-auto transition-all ease-in-out duration-500 opacity-0">
               <span>CHECKOUT</span>
               <span>
                  <font-awesome-icon size="lg" icon="fa-solid fa-chevron-right" />
               </span>
            </div>
         </div>
      </div>
   </div>
   <Loader :show="isLoading" />
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import Dropdown from '@/components/Dropdown.vue'
import { PASSENGERS, DOORS, SUITCASES, TRANSMISSION_TYPES } from '@/utils/constants'
// @ts-ignore
import $ from 'jquery'
import BookingSummary from '@/components/BookingSummary.vue'
import { LOADER_MINIMUM_TIME } from '@/utils/constants'
import CarResult from '@/components/CarResult.vue'
import { UserSearch, Car as CarData } from '@/types'
import { decodeFromQuery, retrieveUserSearch, saveUserTempSearch } from '@/services/search-service'
import CarService from '@/services/car-service'
import CarSkeleton from '@/skeletons/CarSkeleton.vue'
import BookingService from '@/services/booking-service'
import { handleApiError, getSessionPrefix, saveBookingNumber, disableScroll, enableScroll, isLoggedIn } from '@/utils/common'
import { useRouter } from 'vue-router'
import Loader from '@/widgets/Loader.vue'

const props = defineProps(['currentStep'])
const authUser = isLoggedIn()
const router = useRouter()
const passengers = PASSENGERS
const doors = DOORS
const transmissionTypes = TRANSMISSION_TYPES
const suitcases = SUITCASES
const userSearch = ref<UserSearch | null>(null)
const { getCars, saveSelectedCar }  = CarService()
const cars = ref<CarData[] | null>(null)
const isLoading = ref<boolean>(false)
const isBusy = ref<boolean>(false)
const selectedCar = ref<CarData | null>(null)
const { createBooking } = BookingService()
const timeElapsed = ref<number>(0)
const bookingProceed = ref()
const filters = ref<any>({
   passengers: null,
   doors: null,
   suitcases: null,
   transmission: null
})

const filteredCars = computed(() => {
   return cars.value?.filter((car: any) => {
      return (
         (!filters.value.passengers || car.type.passengers === filters.value.passengers) &&
         (!filters.value.doors || car.type.doors === filters.value.doors) &&
         (!filters.value.suitcases || car.type.bags === filters.value.suitcases) &&
         (!filters.value.transmission || car.transmission === filters.value.transmission)
      )
   })
})

const toggleExtraOptions = () => {
   $('.extra-options').slideToggle()
}

const mounted = async () => {
   userSearch.value = retrieveUserSearch()

   isLoading.value = true
   disableScroll()
   const startTime = performance.now()
   const response = await getCars(userSearch.value)
   timeElapsed.value = performance.now() - startTime
   if (timeElapsed.value < LOADER_MINIMUM_TIME) {

      const diff = LOADER_MINIMUM_TIME - timeElapsed.value
      setTimeout(() => {
         enableScroll()
         isLoading.value = false
      }, diff)
   } else {

      enableScroll()
      isLoading.value = false
   }

   if (response && response.success) {
      cars.value = response.data.cars

      if (cars.value?.length == 0) {
         router.push({name: 'BookingLead', params: {service: ''}})
      }
   } else {
      
   }
}

const handleCarSelected = (car: CarData) => {
   selectedCar.value = car
   bookingProceed.value.click()
}

const handleBookingProceed = async () => {

   if (selectedCar.value && !isBusy.value) {
      saveSelectedCar(selectedCar.value)

      isBusy.value = true
      const response = await createBooking(selectedCar.value, userSearch.value as UserSearch)
      isBusy.value = false
      
      if (response.success) {

         const prefix = getSessionPrefix()
         saveBookingNumber(response.data.data.number)

         router.push({name: 'BookingWizard', params: {step: 'contact-details'}})
      } else {
         const error = handleApiError(response)
         console.log(error)
      }
   }
}

const handleDropItemSelected = (item: any, name: string) => {

   if (name == 'passengers') {
      filters.value.passengers = item.value.value === '' ? null : item.value.value
   } else if (name == 'suitcases') {
      filters.value.suitcases = item.value.value === '' ? null : item.value.value
   } else if (name == 'doors') {
      filters.value.doors = item.value.value === '' ? null : item.value.value
   } else if (name == 'transmission') {
      filters.value.transmission = item.value.value === '' ? null : item.value.value
   } 
}

const handleEditSearch = () => {
   const search = retrieveUserSearch()
   saveUserTempSearch(search)
   router.push({name: 'Home'})
}

onMounted(() => {
   mounted()
})

</script>