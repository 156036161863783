<template>
   <div v-if="isLoading"></div> 
   <div v-else-if="hasError" class="flex flex-col gap-3 items-center justify-center w-full h-full">
      <h3 class="text-theme-gray-5 text-head-md-2 md:text-head-xl text-center flex flex-col gap-3 mb-3">
         <div>Error</div>
      </h3>
      <div class="rounded-md bg-red-50 p-4 w-full">
         <div class="flex flex-col items-start gap-2 md:flex-row md:items-center">
            <div class="flex items-start gap-2">
               <font-awesome-icon size="lg" icon="fa-solid fa-times-circle" class="text-red-400" />
               <div class="flex flex-col md:flex-row items-start gap-1">
                  <p class="text-sm font-medium text-red-700">{{ errorMessage }}</p>
                  <div @click="$router.push({name: 'Home'})" class="font-medium text-sm text-red-700 underline hover:text-red-600 cursor-pointer">Go to home</div>
               </div>
            </div>
         </div>
      </div>
   </div> 
   <!--<div v-else class="flex flex-col py-24 px-11 lg:w-theme-dw md:mx-auto md:px-0">
      <h2 class="font-bold text-head-sm">Checkout API Response</h2>
      <div class="grid grid-cols-2 mt-12">
         <div class="col-span-1 min-h-96 border border-gray-300">
            <h3 class="text-center mt-2">Payload</h3>
            <div class="overflow-auto">{{ payload }}</div>
         </div>
         <div class="col-span-1 min-h-96 border-r border-b border-t border-gray-300">
            <h3 class="text-center mt-2">Response</h3>
            <div class="overflow-auto">{{ responsee }}</div>
         </div>
      </div>
   </div>-->
   <div v-else class="grid grid-cols-12 md:gap-10">
      <div v-if="feedType == 'success'" class="col-span-12 md:pb-12 flex flex-col items-center justify-center gap-6">
         <div class="relative">
            <div class="flex items-center justify-center absolute -left-3 w-[100px] h-[100px] rounded-full border border-theme-gray-7">
               <font-awesome-icon size="2xl" icon="fa-solid fa-check" class="text-theme-active" />
            </div>
            <div class="bg-theme-gray-1 w-[100px] h-[100px] rounded-full"></div>
         </div>
         <h3 class="text-theme-gray-5 text-head-md-2 md:text-head-xl text-center flex flex-col gap-3">
            <div>Thank You</div>
         </h3>
         <div class="flex flex-col gap-1 md:w-[80%] mx-auto">
            <div class="rounded-md bg-green-50 p-4">
               <div class="flex flex-col items-start gap-2 md:flex-row md:items-center">
                  <div class="flex items-start gap-2">
                     <font-awesome-icon size="lg" icon="fa-solid fa-check-circle" class="text-green-400" />
                     <div class="flex flex-col md:flex-row items-start gap-1">
                        <p class="text-sm font-medium text-green-800">{{ message }}</p>
                        <div @click="$router.push({name: 'Home'})" class="font-medium text-sm text-green-700 underline hover:text-green-600 cursor-pointer">Go to home</div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <BookingVoucher :bookingNumber="bookingNumber" />
      </div>
      <div v-else-if="feedType == 'error'" class="col-span-12 md:pb-12 flex flex-col items-center justify-center gap-6">
         <div class="relative">
            <div class="flex items-center justify-center absolute -left-3 w-[100px] h-[100px] rounded-full border border-theme-gray-7">
               <font-awesome-icon size="2xl" icon="fa-solid fa-check" class="text-theme-active" />
            </div>
            <div class="bg-theme-gray-1 w-[100px] h-[100px] rounded-full"></div>
         </div>
         <h3 class="text-theme-gray-5 text-head-md-2 md:text-head-xl text-center flex flex-col gap-3">
            <div>Thank You</div>
         </h3>
         <div class="text-center flex flex-col gap-1 md:w-[80%]">
            <div class="rounded-md bg-yellow-50 p-4">
               <div class="flex flex-col items-start gap-2 md:flex-row md:items-center">
                  <div class="flex items-start gap-2">
                     <font-awesome-icon size="lg" icon="fa-solid fa-circle-exclamation" class="text-yellow-400" />
                     <div class="flex flex-col md:flex-row items-start gap-1">
                        <p class="text-sm font-medium text-yellow-700">
                           A booking has been created for you but we did not receive payment from your bank. You will be contacted by our representative for further assistance
                        </p>
                        <div @click="$router.push({name: 'Home'})" class="font-medium text-sm text-yellow-700 underline hover:text-yellow-600 cursor-pointer">Go to home</div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
   <Loader :show="isLoading" />
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import LoaderIcon from '@/components/LoaderIcon.vue'
import { handleApiError, clearSessionData, getBookingNumber, getPaymentSession, getPaymentTransaction, getPaymentCode } from '@/utils/common'
import Payment from '@/composables/Payment'
import BookingService from '@/services/booking-service'
import { useRouter } from 'vue-router'
import CarService from '@/services/car-service'
import Loader from '@/widgets/Loader.vue'
import BookingVoucher from '@/pages/BookingVoucher.vue'

const props = defineProps(['currentStep'])
const isLoading = ref<boolean>(true)
const hasError = ref<boolean>(false)
const booking : string | null = getBookingNumber()
const transaction : string | null = getPaymentTransaction()
const session : string | null = getPaymentSession()
const cvc : string | null = getPaymentCode()
const { authorizePayment  } = Payment()
const payload = ref<any>(null)
const responsee = ref<any>(null)
const { finalizeeBooking  } = BookingService()
const feed = ref<any>()
const feedType = ref<'success' | 'error'>()
const router = useRouter()
const { retrieveUserSelectedCar } = CarService()
const bookingNumber = ref<string>()
const message = ref<string>('Booking has been complete and payment has been recieved')
const errorMessage = ref<string>('')

onMounted(async () => {
   
   bookingNumber.value = getBookingNumber() as string

   if (bookingNumber.value == null || bookingNumber.value == '') {
      router.push({name: 'Home'})
   } else {

      const carResponse: any = retrieveUserSelectedCar()
      if (carResponse && carResponse.price === null) {
         
         feedType.value = 'success'
         const finalResponse = await finalizeeBooking(bookingNumber.value, feedType.value, {}, true)
         isLoading.value = false
         if (finalResponse.success) {
   
            message.value = 'Your Request has been created. You will be contacted by our representative shortly.'
            clearSessionData()
         } else {
            hasError.value = true
         }
      } else {

         const response = await authorizePayment(session, booking, transaction, cvc as string)
         if (response.status == 200) {
      
            const responsee = response.data.response
      
            if (
               !responsee || 
               !responsee.response || 
               !responsee.response.gatewayCode || 
               responsee.response.gatewayCode != 'APPROVED' || 
               !responsee.result || 
               responsee.result != 'SUCCESS'
            ) {
               feed.value = responsee
               feedType.value = 'error'
            } else {
               feed.value = responsee.order
               feedType.value = 'success'
            }
      
            const finalResponse = await finalizeeBooking(bookingNumber.value, feedType.value, feed.value, false)
            isLoading.value = false
            if (finalResponse.success) {
      
               message.value = 'A booking has been created and payment has been received'
               clearSessionData()
            } else {
               hasError.value = true
               errorMessage.value = 'We encountered an error while processing your payment'
            }
         } else {
            isLoading.value = false
            hasError.value = true
            errorMessage.value = handleApiError(response)
         }
      }
   }
})

</script>