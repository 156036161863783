
import Car from "@/composables/Car"
import { Locality, UserSearch, Car as CarData, PaymentDetails } from "@/types"
import { getSessionPrefix, handleApiError } from "@/utils/common"
import Booking from '@/composables/Booking'

const { saveBooking, updateeBooking, fetchBooking, finalizeBooking }  = Booking()

const BookingService = () => {

   const createBooking = async (car: CarData, search : UserSearch) => {
      try {

         const response = await saveBooking(car, search)

         if (response.status === 201) {
            return { success: true, data: response.data }
         } else {
            return { success: false, error: handleApiError(response) }
         }
      } catch (err) {
         return { success: false, error: err }
      }
   }

   const updateBooking = async (bookingNumber: string, contactDetails: any, paymentDetails: any, needConfirmation: boolean) => {
      try {

         const response = await updateeBooking(bookingNumber, contactDetails, paymentDetails, needConfirmation)

         if (response.status === 200) {
            return { success: true, data: response.data }
         } else {
            return { success: false, error: handleApiError(response) }
         }
      } catch (err) {
         return { success: false, error: err }
      }
   }

   const finalizeeBooking = async (bookingNumber: string, type: 'success' | 'error', responsee: any, needConfirmation: boolean) => {
      try {

         const response = await finalizeBooking(bookingNumber, type, responsee, needConfirmation)

         if (response.status === 200) {
            return { success: true, data: response.data }
         } else {
            return { success: false, error: handleApiError(response) }
         }
      } catch (err) {
         return { success: false, error: err }
      }
   }

   const readBooking = async (bookingNumber: string) => {
      try {

         const response = await fetchBooking(bookingNumber)

         if (response.status === 200) {
            return { success: true, data: response.data }
         } else {
            return { success: false, error: handleApiError(response) }
         }
      } catch (err) {
         return { success: false, error: err }
      }
   }

   return {
      createBooking,
      readBooking,
      updateBooking,
      finalizeeBooking
   }
}

export default BookingService
