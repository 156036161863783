import { getAdminToken, getBackendBaseUrl } from "@/utils/common"
import { Locality } from '@/types/index'
import axios from "axios"

const baseUrl : string = getBackendBaseUrl()

const Admin = () => {

   const getHeaders = () => { 
      return {
         'Authorization': `Bearer ${getAdminToken()}`,
         'Content-Type': 'application/json',
      }
   }

   const allCars = async () : Promise<any> => {
      try {
         const response = await axios.get(baseUrl + 'api/admin/cars/list', {params: {}, headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }

   const allBookings = async () : Promise<any> => {
      try {
         const response = await axios.get(baseUrl + 'api/admin/bookings/list', {params: {}, headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }

   const allCarTypes = async () : Promise<any> => {
      try {
         const response = await axios.get(baseUrl + 'api/admin/car-types/list', {params: {}, headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }

   const allSuppliers = async () : Promise<any> => {
      try {
         const response = await axios.get(baseUrl + 'api/admin/suppliers/list', {params: {}, headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }

   const createCar = async (data: any) : Promise<any> => {
      try {
         const response = await axios.post(baseUrl + 'api/admin/cars/create', {name: data.name, photo: data.photo, type: data.type, suppliers: data.suppliers, displacement: data.displacement, transmission: data.transmission, meet_greet: data.meet_greet}, {headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }
   
   const updateCar = async (id: number, data: any) : Promise<any> => {
      try {
         const response = await axios.put(`${baseUrl}api/admin/cars/update/${id}`, {name: data.name, photo: data.photo, type: data.type, suppliers: data.suppliers, displacement: data.displacement, transmission: data.transmission, meet_greet: data.meet_greet}, {headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }

   const allLocations = async (type: string) : Promise<any> => {
      try {
         const response = await axios.get(baseUrl + `api/admin/locations/${type}/list`, {params: {}, headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }

   const carLocations = async (id: number, type: string) : Promise<any> => {
      try {
         const response = await axios.get(baseUrl + `api/admin/cars/${id}/locations/${type}`, {params: {}, headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }

   const createCarChaufferLocation = async (id: number, location: Locality, rate: number) : Promise<any> => {
      try {
         const response = await axios.put(baseUrl + `api/admin/cars/${id}/locations/chauffer`, {locality: location, rate: rate}, {headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }

   const createCarTransferLocation = async (id: number, fromLocation: Locality, toLocation: Locality, rate: number) : Promise<any> => {
      try {
         const response = await axios.put(baseUrl + `api/admin/cars/${id}/locations/transfer`, {from_locality: fromLocation, to_locality: toLocation, rate: rate}, {headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }

   const removeCar = async (id: number) : Promise<any> => {
      try {
         const response = await axios.delete(`${baseUrl}api/admin/cars/${id}/delete`, { headers: getHeaders(), data: { id: id } })
         return response
      } catch (err : any) {
         return err
      }
   }

   const removeCarLocation = async (id: number, type: string) : Promise<any> => {
      try {
         const response = await axios.delete(`${baseUrl}api/admin/cars/locations/${type}/${id}/delete`, { headers: getHeaders(), data: { id: id } })
         return response
      } catch (err : any) {
         return err
      }
   }

   return {
      allCars,
      allCarTypes,
      allSuppliers,
      createCar,
      allLocations,
      carLocations,
      createCarChaufferLocation,
      createCarTransferLocation,
      allBookings,
      removeCar,
      removeCarLocation,
      updateCar
   }
}

export default Admin